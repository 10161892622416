<template>
  <div class="practice-invite">
    <div v-if="data != null" class="wrap">
      <h1>PIPA邀请函</h1>
      <div class="logo-wrap">
        <img class="logo" src="../../../assets/images/logo.png">
        <p>{{slogan}}</p>
      </div>
      <div class="invite-tip-wrap">
        <div class="inviter">
          <img :src="data.inviter.avatar">
        </div>
        <div class="invite-info">
          <h2>快来和我一起参加枇杷村「{{data.practice.positionLevel | positionLevelText}}{{data.position.name}}」专项练习</h2>
          <p>一共 {{data.questionCount}} 题，大约耗时 {{ data.questionCount * 30 | secondsText }}</p>
        </div>
      </div>
      <!-- 技术分析 -->
      <div class="tech-table-wrap">
        <h3>练习题技术分析</h3>
        <div class="tech-table">
          <ul class="title-bar">
            <li>技术</li>
            <li>技术占比</li>
          </ul>
          <ul v-for="tech in data.techList" :key="tech.id" class="data-list">
            <li>{{tech.techName}}</li>
            <li>{{tech.ratio}}%</li>
          </ul>
        </div>
      </div>
      <!-- 已参加 -->
      <div class="user-list-wrap">
        <h3>已参赛</h3>
        <ul>
          <li v-for="user in data.userList" :key="user.id">
            <img :src="user.avatar">
            <p>{{user.nickname}}</p>
          </li>
        </ul>
      </div>
      <!-- 站长提示 -->
      <div class="tip-wrap">
        <label>站长提醒：</label>
        <p>请警惕邀请你参加专项练习的好友，TA可能单纯的想虐你！练习中你无需争取获得高分，只需要确保知识点装进你的脑子！去吧，伟大的中国程序员～</p>
      </div>
      <!-- 操作 -->
      <div class="opera-wrap">
        <Button v-if="userInfo != null" type="solid" theme="function" size="big" :disabled="isWorking" @click="join">立即加入</Button>
        <div class="login-tip" v-else>
          <p>登录后才可以参与练习，没有账号直接微信扫码登录就可以了哦～</p>
          <Button size="big" type="outline" theme="function" @click="toLogin">登录 or 注册，开启pipa之旅</Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import UserMedals from '@/components/common/usr/UserMedals'
import { getInvitePracticeInfo } from '@/api/invite'
import { join } from '@/api/user.practice'
import BaseUser from '@/components/base/BaseUser'

export default {
  // components: { UserMedals },
  extends: BaseUser,
  data () {
    return {
      isWorking: false,
      // 网站标语
      slogan: this.$consts.SLOGAN,
      // 数据
      data: null
    }
  },
  methods: {
    // 获取邀请信息
    getInvitePracticeInfo () {
      getInvitePracticeInfo({
        practiceNo: this.$route.params.practiceNo,
        inviteCode: this.$route.query.invite_code
      })
        .then(data => {
          this.data = data
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
    },
    // 加入练习
    join () {
      if (this.isWorking) {
        return
      }
      this.isWorking = true
      join({
        practiceNo: this.$route.params.practiceNo,
        inviteCode: this.$route.query.invite_code
      })
        .then(() => {
          this.$router.push({ name: 'PracticeExecute', params: { practiceNo: this.$route.params.practiceNo } })
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
        .finally(() => {
          this.isWorking = false
        })
    }
  },
  created () {
    this.getInvitePracticeInfo()
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/style/variables";
.practice-invite {
  height: 100vh;
  background-color: $background-color-second;
  overflow-y: auto;
  .wrap {
    width: 700px;
    margin: 20px auto;
    border-radius: 30px;
    background-color: #fff;
    padding: 20px 60px;
    h1 {
      font-weight: normal;
      text-align: center;
      font-size: 30px;
    }
    .logo-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 200px;
        border-radius: 50%;
        animation: dynamicLogo 10s infinite;
      }
      p {
        margin: 0;
      }
    }
    & > div {
      display: flex;
      background-color: #fff;
      // border-radius: 10px;
      padding: 20px;
      h3 {
        font-weight: normal;
        color: $font-color-third;
        position: relative;
        padding-left: 10px;
        &::before {
          content: '';
          width: 4px;
          height: 15px;
          background-color: $primary-color;
          border-radius: 2px;
          position: absolute;
          left: 0;
          top: 4px;
        }
      }
    }
    // 邀请提示
    .invite-tip-wrap {
      // 邀请人
      .inviter {
        flex-shrink: 0;
        text-align: center;
        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }
        & > div {
          padding-left: 10px;
          p {
            margin: 0;
          }
        }
      }
      // 邀请信息
      .invite-info {
        padding-left: 10px;
        h2 {
          font-size: 18px;
          margin: 0 0 5px 0;
          font-weight: bold;
        }
        p {
          margin: 0;
          color: $font-color-third;
        }
      }
    }
    // 参赛人员
    .user-list-wrap {
      display: block;
      ul {
        display: flex;
        margin: 0;
        padding: 0;
        li {
          width: 12.5%;
          flex-wrap: wrap;
          text-align: center;
          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
          }
          p {
            margin: 0;
            font-size: 12px;
          }
        }
      }
    }
    // 技术分析
    .tech-table-wrap {
      display: block;
      .tech-table {
        padding: 15px 10px;
        background-color: $background-color-second;
        border-radius: 15px;
        // 比列名称
        .title-bar {
          margin: 0 0 15px 0;
          padding: 0 20px;
          font-size: 17px;
          color: $font-color-second;
          display: flex;
          & > li {
            width: 50%;
            flex-shrink: 0;
          }
        }
        // 比列数据
        .data-list {
          margin: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $background-color;
          padding: 15px 20px;
          border-radius: 30px;
          font-size: 18px;
          &:nth-of-type(odd) {
            background-color: $background-color-second;
          }
          li {
            width: 50%;
            flex-shrink: 0;
            &:first-of-type {
              font-size: 17px;
              text-align: left;
              justify-content: left;
            }
          }
        }
      }
    }
    .tip-wrap {
      display: block;
      label {
        color: $font-color-third
      }
      p {
        margin: 10px 0 0 0;
        line-height: 25px;
        font-weight: bold;
        letter-spacing: 1px;
      }
    }
    // 操作
    .opera-wrap {
      display: flex;
      justify-content: center;
      .el-button {
        padding: 15px 60px;
        font-size: 16px;
        border-radius: 50px;
      }
      .login-tip {
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
          color: $font-color-third;
          padding: 5px 10px;
          border-radius: 10px;
          background-color: $background-color-second;
        }
      }
    }
  }
  // logo动画
  @keyframes dynamicLogo {
    0% {
      transform: skewX(30deg);
    }
    1% {
      transform: skewX(-30deg);
    }
    2% {
      transform: skewX(25deg);
    }
    3% {
      transform: skewX(-25deg);
    }
    4% {
      transform: skewX(20deg);
    }
    5% {
      transform: skewX(-20deg);
    }
    6% {
      transform: skewX(15deg);
    }
    7% {
      transform: skewX(-15deg);
    }
    8% {
      transform: skewX(0);
    }
  }
}
</style>
